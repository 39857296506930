import React, { Fragment } from "react"

import CardButtons from "../Elements/CardButtons"

import { isBrowser } from "services/general"
import { pageNavigation } from "./utils/pageNavigation"

const PageNavigation = () => {
  const shouldDisablePath = (path) => {
    let enrollment = {}
    if (isBrowser())
      enrollment = JSON.parse(sessionStorage?.getItem("enrollment"))
    if (!enrollment?.allowedPaths?.includes(path)) return true
    return false
  }

  return (
    <Fragment>
      <p className="has-text-centered is-size-5">
        The web app is a collection of tools and useful functions <br />
        to access MedGrocer IT's cloud database.
      </p>
      <CardButtons
        buttons={pageNavigation()}
        shouldDisablePath={shouldDisablePath}
      />
    </Fragment>
  )
}

export default PageNavigation
