import React from "react"

import Layout from "layout"
import PageNavigation from "../components/Home"
import PrivateRoute from "../components/PrivateRoute"

const IndexPage = () => {
  return (
    <Layout seoTitle="Firestore Archiver" title="Welcome to MG Kit">
      <PrivateRoute isPrivate={true}>
        <PageNavigation />
      </PrivateRoute>
    </Layout>
  )
}

export default IndexPage
