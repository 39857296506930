export const pageNavigation = () => {
  return [
    [
      {
        label: "Archive Data",
        img: "upload",
        subtitle: "Upload data to the cloud",
        link: "/archiver",
      },
      {
        label: "Extract Data",
        img: "extract",
        subtitle: "Extract data from the cloud",
        link: "/extractor",
      },
      {
        label: "Delete Data",
        img: "delete",
        subtitle: "Delete data from the cloud",
        link: "/delete",
      },
    ],
  ]
}
