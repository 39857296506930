import React from "react"
import Img from "gatsby-image"
import classNames from "classnames"

import styles from "./utils/elements.module.scss"
import useCardButtonImages from "./hooks/useCardButtonImages"
import { navigate } from "gatsby"

const CardButtons = ({ shouldDisablePath, buttons }) => {
  const data = useCardButtonImages()

  return (
    <div>
      {buttons?.map((buttonList) => {
        return (
          <div className="columns is-centered m-0 mt-3">
            {buttonList?.map((button) => {
              return (
                <div classname="column">
                  <div
                    className={classNames(
                      "is-flex is-justify-content-center",
                      styles["cardLinkContainer__card"]
                    )}
                  >
                    <button
                      className={classNames(
                        "is-block mr-1 mr-0-mobile mt-1-mobile",
                        styles["cardLinkContainer"]
                      )}
                      disabled={shouldDisablePath(button?.link)}
                      onClick={() => {
                        navigate(button?.link)
                        if (button?.onClick) button.onClick()
                      }}
                    >
                      <div
                        className={classNames(
                          "has-text-centered",
                          styles["cardLinkContainer__cardButton"],
                          { "is-disabled": shouldDisablePath(button?.link) }
                        )}
                      >
                        <div className="pt-2">
                          <Img
                            fixed={data[button?.img]?.childImageSharp?.fixed}
                          />
                          <div className="card-content">
                            <div className="content is-size-6">
                              <p className={styles["cardLinkContainer__label"]}>
                                {button?.label}
                              </p>
                              {button?.subtitle && (
                                <p className="has-text-grey-light is-size-6">
                                  {button?.subtitle}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default CardButtons
