import { useStaticQuery, graphql } from "gatsby"

const useNavigationImages = () => {
  const data = useStaticQuery(graphql`
    {
      upload: file(relativePath: { eq: "navigation/upload.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      extract: file(relativePath: { eq: "navigation/extract.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      split: file(relativePath: { eq: "navigation/split.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      delete: file(relativePath: { eq: "navigation/delete.png" }) {
        childImageSharp {
          fixed(width: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}

export default useNavigationImages
