import React, { Fragment } from "react"
import Loading from "elements/Loading"
import { navigate } from "gatsby"
import { isBrowser } from "../services/general"
import { hasSignedInUser } from "./Auth/services/user"

const PrivateRoute = ({ isPrivate, children }) => {
  if (isPrivate && isBrowser()) {
    if (!hasSignedInUser()) {
      navigate("/sign-in")
      return <Loading />
    }
  }

  return <Fragment>{children}</Fragment>
}

export default PrivateRoute
